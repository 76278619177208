<template>
  <div>
    <PlateTitle title="申请邀约函" />
    <div class="main">
      <div class="content">
        123
      </div>
      <div class="know">
        <a-checkbox
          class="checkbox"
          @change="handleKenwChange"
        >
          我已阅读并同意<b>《投标保函申请邀约函》</b>
        </a-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from 'ant-design-vue';
import PlateTitle from '@/components/PlateTitle';

export default {
  name: 'InvitationLetter',
  components: {
    PlateTitle,
    aCheckbox: Checkbox,
  },
  methods: {
    handleKenwChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
};
</script>

<style lang="less" scoped>
.main{
  margin-top: 35px;
  padding: 20px;
  background-color:#F5F6FB;
  .content{
    background-color: #fff;
    height: 240px;
    font-size: 12px;
    color:#666;
    padding: 35px 60px;
  }
  .know{
    margin-top: 25px;
    font-size: 14px;
    color:#666;
    .checkbox{
      margin-right: 10px;
    }
    b{
      color:#1372BF
    }
  }
}
</style>
