<template>
  <div class="operate">
    <Button
      size="large"
      class="btn cancel"
    >
      返回修改
    </Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <Button
      size="large"
      class="btn submit"
      html-type="submit"
      type="primary"
    >
      立即投保
    </Button>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';

export default {
  name: 'InsureConfirmPageOperate',
  components: {
    Button,
  },
};
</script>

<style lang="less" scoped>
.operate{
  width: 100%;
  text-align: center;
  .btn{
    width: 200px;
    height: 48px;
    &.cancel{
      border-color:#40a9ff;
      color:#40a9ff
    }
    &.submit{
      margin-left:50px;
    }
  }
}
</style>
