<template>
  <div class="summary">
    <div class="right">
      应付保费总计 <strong>¥{{ amountFormat(totalAmount) }}</strong>
    </div>
  </div>
</template>

<script>
import { amountFormat } from '@/utils/format';
export default {
  name: 'InsureConfirmPageSummary',
  props: {
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    amountFormat,
  },
};
</script>

<style lang="less" scoped>
.summary{
  overflow: hidden;
}
.right{
  float:right;
  text-align: right;
  font-size: 16px;
  color: #666;
  strong{
    margin-left: 15px;
    font-size: 26px;
    color: #E12D2D;
  }
}
</style>
